/** События ramblerIdHelper */
export enum Events {
  /** @internal */
  INSTALL = 'install',
  /** @internal */
  INSTALLED = 'installed',

  ERROR = 'error',
  REGISTER = 'register',
  LOGIN = 'login',
  OAUTHLOGIN = 'oauthlogin',
  OAUTHERROR = 'oautherror',
  LOGOUT = 'logout',
  CLOSE = 'close',
  REDIRECT = 'redirect',
  RESIZE = 'resize',
  LOADED = 'loaded',
  DESTROY = 'destroy',
  NAVIGATE = 'navigate',

  /** @internal */
  REQUEST_QR_CODE = 'request_qr_code',
  /** @internal */
  RESPONSE_QR_CODE = 'response_qr_code'
}
