import {getFirstPartyOrigin} from '@rambler-id/itp'
import {Events} from '../utils/events'
import {randomId} from '../utils/string'
import {NAMESPACE, RAMBLER_SLD_MATCH} from '../utils/constants'
import {EventEmitter} from '../utils/event-emitter'
import {
  HelperCallback,
  HelperEvent,
  HelperEventType,
  HelperEventData,
  HelperEventMeta
} from '../types'
import {createDebug} from '@rambler-id/debug'

const CDN_ORIGIN = process.env.CDN_ORIGIN
const CDN_PREFIX = process.env.CDN_PREFIX
const VERSION = process.env.VERSION

const debug = createDebug('id:helper:emitter')

export interface SharedEmitterSettings {
  storage: boolean
}

export class SharedEmitter extends EventEmitter {
  // NOTE events that sync to parent and all children with *.rambler.ru origin
  private AUTH_EVENTS = [Events.LOGIN, Events.OAUTHLOGIN, Events.LOGOUT]

  // NOTE events that expected only from id.rambler.ru origin
  private ID_EVENTS = [
    Events.INSTALLED,
    Events.REGISTER,
    Events.CLOSE,
    Events.REDIRECT,
    Events.RESIZE,
    Events.LOADED,
    Events.NAVIGATE,
    Events.REQUEST_QR_CODE,
    Events.OAUTHERROR
  ]

  // NOTE we can not propagate errors to parent windows
  // due instance of Error can not be deeply cloned in postMessage
  private PROPAGATED_EVENTS = [
    ...this.AUTH_EVENTS,
    ...this.ID_EVENTS,
    Events.RESPONSE_QR_CODE
  ]

  private settings: SharedEmitterSettings
  private firstPartyOrigin = getFirstPartyOrigin()
  protected currentTabId: string = randomId()
  private latestEventId?: string
  private storage?: HTMLIFrameElement

  public constructor(settings: SharedEmitterSettings) {
    super()
    this.settings = settings
    this.install()
  }

  protected get isRamblerOrigin(): boolean {
    return RAMBLER_SLD_MATCH.test(this.firstPartyOrigin)
  }

  private install(): void {
    if (this.settings.storage) {
      this.storage = document.createElement('iframe')
      // TODO how test helper storage on id.smth?
      //
      // id.rambler.ru
      // ramblerIdHelper
      // id.smth/rambler-id-helper/storage
      // proxy_pass id-ext.rambler.ru
      // proxy_pass id.rambler.ru
      //
      // mft.mail.rambler.ru
      // ramblerIdHelper
      // id.smth/rambler-id-helper/storage
      // proxy_pass id-ext.rambler.ru
      // proxy_pass mft.mail.rambler.ru
      this.storage.src = `${this.firstPartyOrigin}${CDN_PREFIX}/${VERSION}/storage.html`
      this.storage.style.width = '0'
      this.storage.style.height = '0'
      this.storage.style.border = '0'
      this.storage.style.position = 'absolute'
      this.storage.style.left = '-9999px'
      this.storage.setAttribute('aria-hidden', 'true')
      this.storage.setAttribute('loading', 'eager')

      this.storage.onload = () => {
        const message: HelperEvent = {
          eventType: Events.INSTALL,
          emitter: NAMESPACE,
          data: {
            providerOrigin: window.location.href
          }
        }

        try {
          this.storage?.contentWindow?.postMessage(
            message,
            this.firstPartyOrigin
          )
          this.emit(Events.INSTALLED)
        } catch (error) {
          this.emit(Events.ERROR, new Error('storage is not installed'))
        }
      }

      this.storage.onerror = () => {
        this.emit(Events.INSTALLED)
        this.emit(Events.ERROR, new Error('storage is not loaded'))
      }

      if (document.body) {
        document.body.appendChild(this.storage)
      }
    }

    window.addEventListener('message', this.onMessage, false)
  }

  private isAuthEvent(eventType: HelperEventType): boolean {
    return this.AUTH_EVENTS.includes(eventType)
  }

  private isEventOnlyFromId(eventType: HelperEventType): boolean {
    return this.ID_EVENTS.includes(eventType)
  }

  private isPropagatedEvent(eventType: HelperEventType): boolean {
    return this.PROPAGATED_EVENTS.includes(eventType)
  }

  private onMessage = ({
    data: event,
    origin,
    source
  }: MessageEvent<HelperEvent>): void => {
    if (!event || typeof event !== 'object') {
      return
    }

    const {
      emitter: ns,
      eventType,
      redirectUrl,
      data,
      // fallback for non auth events
      frameInfo = data || {},
      ...meta
    } = event

    // NOTE source - это экземпляр родительского окно,
    // когда мы открываем фрейм регистрации
    if (source) {
      meta.source = source
    }

    if (
      ns !== NAMESPACE ||
      (this.isEventOnlyFromId(eventType) && origin !== CDN_ORIGIN)
    ) {
      return
    }

    // fallback for `redirect` event
    if (
      redirectUrl &&
      typeof frameInfo === 'object' &&
      !(frameInfo instanceof Error)
    ) {
      frameInfo.redirectUrl = redirectUrl
    }

    if (this.isAuthEvent(eventType)) {
      this.emit(eventType, data, meta)
    } else {
      // NOTE Передадим meta, в поле source будет экземпляр родительского окна фрейма
      // Из этого эмита мы попадем в обработчик события this.requestQRCode
      this.emit(eventType, frameInfo, redirectUrl ?? meta)
    }
  }

  private getBroadcastNodes(eventType: HelperEventType): Window[] {
    const firstPartyDomain = new URL(this.firstPartyOrigin).hostname
    const firstPartyDomainEscaped = firstPartyDomain.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )
    /* eslint-disable-next-line security/detect-non-literal-regexp */
    const firstPartyRegExp = new RegExp(
      `^https?:\\/\\/${firstPartyDomainEscaped}\\/?`
    )
    const frames = this.isAuthEvent(eventType)
      ? Array.prototype.slice
          .call(document.getElementsByTagName('iframe'))
          .filter((frame) => {
            return (
              RAMBLER_SLD_MATCH.test(frame.src) ||
              firstPartyRegExp.test(frame.src)
            )
          })
          .map((frame) => frame.contentWindow)
      : []
    const {opener, parent} = window

    return [opener, parent !== window && parent].concat(frames).filter(Boolean)
  }

  private notifyBroadcastNodes(event: HelperEvent, target?: Window): void {
    // NOTE В поле таргет у нас родитель фрейма, соответственно если он есть
    // то вызовет событие event.eventType response_qr_code
    try {
      const broadcastNodes = target
        ? [target]
        : this.getBroadcastNodes(event.eventType)

      broadcastNodes.forEach((node) => {
        node.postMessage(event, '*')
      })
    } catch {}
  }

  private notifyListeners = (
    eventType: HelperEventType,
    ...args: unknown[]
  ): void => {
    const {listeners} = this
    // NOTE copy listeners to prevent change during the emit
    const eventListeners = [...(listeners[eventType] ?? [])]

    eventListeners.forEach((callback: HelperCallback) => {
      callback(...args)
    })
  }

  /**
   * Запуск события
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  public emit(
    eventType: HelperEventType,
    data?: HelperEventData | Error | string | boolean | number,
    meta?: HelperEventMeta | string | boolean | number,
    ...args: unknown[]
  ): void {
    debug(`emit ${eventType} %o %o`, data, meta)

    let redirectUrl

    if (this.isPropagatedEvent(eventType)) {
      const {currentTabId, latestEventId} = this
      const eventMeta = !meta || typeof meta !== 'object' ? {} : meta
      const {tabId = null, time = Date.now(), ...rest} = eventMeta
      const eventId = `${eventType}:${time}`

      if (eventId === latestEventId) {
        return
      }

      const eventData =
        !data || typeof data !== 'object' || data instanceof Error ? {} : data
      const {silentCurrentTab = rest.silentCurrentTab, redirectUrl: url = ''} =
        eventData
      const eventOnCurrentTab = !tabId || tabId === currentTabId
      const event: HelperEvent = {
        emitter: NAMESPACE,
        eventType,
        time,
        data,
        tabId: tabId || currentTabId
      }

      // NOTE Если у нас есть eventMeta.target значит мы заэмитили событие RESPONSE_QR_CODE
      // И передали в поле target source - родитель фрейма
      this.notifyBroadcastNodes(event, eventMeta.target)
      this.latestEventId = eventId
      redirectUrl = url

      if (eventOnCurrentTab && silentCurrentTab) {
        return
      }
    }

    const listenerArgs: [HelperEventType, ...unknown[]] = [
      eventType,
      data,
      // fallback for `redirect` event to pass (frameInfo, redirectUrl) to listener
      redirectUrl || meta,
      ...args
    ]

    if (!this.storage) {
      this.notifyListeners(...listenerArgs)

      return
    }

    window.setTimeout(this.notifyListeners, 10, ...listenerArgs)
  }

  public destroy(): void {
    this.listeners = {}

    if (this.storage) {
      document.body.removeChild(this.storage)
      delete this.storage
    }

    window.removeEventListener('message', this.onMessage, false)
  }
}
